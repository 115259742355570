<template>
  <div>
    <image-header :text="$t('video.image-header')">
      <!-- 9, 10?, 11?, 12 -->
      <img  src="@/assets/header_images/10.jpeg">
    </image-header>
    <content-sheet>
      <!-- <h1 class="text--primary">Samlingar</h1> -->
      <div class="video-collection-grid" style="margin-top: 0px">
        <div v-for="(video, index) in collections" :key="index">
          <video-collection-card :collectionData="video" />
        </div>
      </div>
    </content-sheet>
  </div>
</template>

<script>
import ContentSheet from '../components/ContentSheet.vue'
import ImageHeader from '../components/ImageHeader.vue'
import VideoCollectionCard from '../components/videos/VideoCollectionCard.vue'

export default {
  components: {
    ContentSheet,
    ImageHeader,
    VideoCollectionCard,
  },
  metaInfo() {
    return {
      title: this.$t('navbar.videos')
    }
  },
  computed: {
    collections() {
      const collections = this.$store.state.videoCollections

      //Filters out collections that are not translated for the english version of the page.
      return collections.filter((collection) => {
        // if(this.$i18n.locale !== 'sv') {
          // return collection.title[1] && collection.description[1] && collection.videos.every((v) => v.title[1])
        // } 
        return true
      })
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style>
.video-collection-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 20fr));
  column-gap: 70px;
  row-gap: 70px;
  justify-content: flex-start;
}


</style>